// in gastby-browser.js


const React = require("react");
const BreakpointProvider = require("./src/hooks/usebreakpoints").BreakpointProvider;

// exports.shouldUpdateScroll = ({
//                                   routerProps: {location},
//                                   getSavedScrollPosition,
//                               }) => {
//     // const { pathname } = location
//     // list of routes for the scroll-to-top-hook
//     // const scrollToTopRoutes = [`/`]
//     // if the new route is part of the list above, scroll to top (0, 0)
//     // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     // }
//     // window.scrollTo(0, 0);
//     return false
// }
exports.onRouteUpdate = ({location, prevLocation}) => {
    // if (typeof window.gtag !== 'undefined') {
    // }
    const currentEnvironment = process.env.GATSBY_DEPLOYMENT || `development`

    console.log("current env " + currentEnvironment +" " + JSON.stringify(process.env.GATSBY_DEPLOYMENT));
}

exports.wrapPageElement = ({element, props}) => {
    return <BreakpointProvider>
            {element}
        </BreakpointProvider>
}

require("./src/globalcss/global.css")
