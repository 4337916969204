import React, {createContext, useContext, useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";


const breakpoints = {
    xxs: '(max-width: 468px)',
    xs: '(max-width: 579px)',
    s: '(max-width: 659px)',
    m: '(max-width: 759px)',
    l: '(max-width: 878px)',
    xl: '(max-width: 999px)',
    xxl: '(max-width: 1300px)',
}

type BreakpointType = keyof typeof breakpoints;

const defaults: {[key in BreakpointType]?: boolean} = {};

const BreakpointContext = createContext(defaults);

export const BreakpointProvider = ({children}) => {


    let data: {[key in BreakpointType]?: boolean} = {};

    const breakpointTags = Object.keys(breakpoints);
    breakpointTags.map((it) => {
        const key = it as keyof typeof breakpoints;
        const query = breakpoints[it as BreakpointType];
        const res = useMediaQuery({query: query});

        data[key] = res;
    });


    return (
        <BreakpointContext.Provider value={data}>
            {children}
        </BreakpointContext.Provider>
    );
}

function useBreakpoint() {
    const context = useContext(BreakpointContext);
    if (context === undefined) {
        throw new Error("Not inside hook");
    }
    return context;
}

export {
    useBreakpoint, BreakpointContext
}
