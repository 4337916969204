exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("./../../../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ourwork-tsx": () => import("./../../../src/pages/ourwork.tsx" /* webpackChunkName: "component---src-pages-ourwork-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-sent-tsx": () => import("./../../../src/pages/sent.tsx" /* webpackChunkName: "component---src-pages-sent-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-projectdetails-tsx": () => import("./../../../src/templates/projectdetails.tsx" /* webpackChunkName: "component---src-templates-projectdetails-tsx" */),
  "component---src-templates-servicedetails-tsx": () => import("./../../../src/templates/servicedetails.tsx" /* webpackChunkName: "component---src-templates-servicedetails-tsx" */)
}

